import React, { useEffect, useMemo, useState } from "react";

import ReactFlow, {
  MiniMap,
  Background,
  BackgroundVariant,
  useNodesState,
  useEdgesState,
  Panel,
} from "reactflow";
import "reactflow/dist/base.css";

import { ExternalLinkIcon } from "./icons";
import { NODE_WIDTH, NODE_HEIGHT, NODE_BORDER } from "./constants";

// @ts-ignore
import styles from "./flow.module.css";

const defaultEdgeOptions = {
  animated: true,
  type: "default",
};

const Flow = (props) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(props.initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(props.initialEdges);
  const [selectedNodeID, setSelectedNodeID] = useState("");


  const closePanel = () => {
    setSelectedNodeID(undefined);
  };

  const getNodeSelected = (event, node) => {
    console.log(node);
    return selectedNodeID === node.id
      ? setSelectedNodeID(undefined)
      : setSelectedNodeID(node.id);
  };

  useEffect(() => {
    setSelectedNodeID(nodes[0].id);
  }, []);

  useEffect(() => {
    setNodes(
      props.initialNodes.map((node) => {
        return {
          ...node,
          data: {
            ...node.data,
            selected: node.id === selectedNodeID,
          },
        };
      })
    );
  }, [selectedNodeID, setNodes, props.initialNodes]);

  const nodeColor = (node) => {
    return node?.data?.color || "#ccc";
  };

  const selectedNode = useMemo(() => {
    return nodes.find((node) => node.id === selectedNodeID);
  }, [selectedNodeID, nodes]);

  const validLink = useMemo(() => {
    if (!selectedNode?.data?.link) return "";
    const hasLink = selectedNode?.data?.nextFiles.some((file) => {
      return file.name.match("page") || file.name.match("route");
    });
    return hasLink ? selectedNode?.data?.link : "";
  }, [selectedNode]);

  return (
    <div
      className={styles.flow}
      data-menu={selectedNodeID !== undefined ? "true" : "false"}
    >
      <ReactFlow
        nodes={nodes}
        onNodesChange={onNodesChange}
        edges={edges}
        onNodeClick={getNodeSelected}
        onEdgesChange={onEdgesChange}
        defaultEdgeOptions={defaultEdgeOptions}
        zoomOnDoubleClick={false}
        fitView
        snapToGrid
        proOptions={{
          hideAttribution: true,
        }}
        className={styles.reactFlow}
      >
        <Background
          color="#fafafa"
          variant={BackgroundVariant.Lines}
          gap={[NODE_WIDTH, NODE_HEIGHT]}
          lineWidth={NODE_BORDER}
        />
        <MiniMap
          nodeColor={nodeColor}
          nodeStrokeWidth={3}
          zoomable
          pannable
          position="bottom-right"
        />
      </ReactFlow>
    </div>
  );
};

export default Flow;