import React, { useCallback, useEffect, useState } from "react";
import { useNodesState, useEdgesState, addEdge } from 'reactflow';
import Flow from './Flow';


import './App.css';
import 'reactflow/dist/style.css';

const X_SEP = 200;
const Y_SEP = 100;


const WFJSTypes = {
  "Bool": "text",
  "Int": "number",
  "Bytes": "text",
  "Ip": "text",
  "Map": "text", // JSON -> JS object
  "Array": "text" // JSON -> JS object
}

// get nodes and edges
const parseAST = (ast, x, y) => {
  const nodes = []
  const edges = []
  if (!ast.hasOwnProperty('items')) {
    // leaf (lhs + ops + rhs)
    const { lhs, op, rhs } = ast;
    const opId = `${x}-${y}-op`;
    const lhsId = `${x}-${y}-lhs`;
    const rhsId = `${x}-${y}-rhs`;
    nodes.push(
      {id: opId, position: {x: x, y: y}, data: { label: op}, draggable: false},
      {id: lhsId, position: {x: x - X_SEP / 2, y: y + Y_SEP}, data: { label: lhs}, draggable: false},
      {id: rhsId, position: {x:  x + X_SEP / 2, y: y + Y_SEP}, data: { label: rhs}, draggable: false},
    )
    edges.push(
      {id: `e-${x}-${y}-lhs`, source: opId, target: lhsId},
      {id: `e-${x}-${y}-rhs`, source: opId, target: rhsId},
    )
  } else {
    // add current op
    const { op } = ast;
    const opId = `${x}-${y}-op`;
    let maxX = x;
    let minX = x;

    for (let i = 0; i < ast.items.length; i++) {
      const item = ast.items[i];
      const [childNodes, childEdges] = parseAST(item, maxX + (X_SEP * i * 2), y + Y_SEP);
      nodes.push(...childNodes);
      edges.push(...childEdges);
      maxX = Math.max(...childNodes.map((c) => c.position.x))
      minX = Math.min(...childNodes.map((c) => c.position.x))
      
      // add edge to current op
      edges.push(
        {id: `e-${x}-${y}-${op}-${i}`, source: opId, target: childNodes[0].id},
      )
    }
    nodes.push({id: opId, position: {x: maxX / 2, y: y}, data: {label: op}, draggable: false});

  }
  console.log(nodes, edges);
  return [nodes, edges];
}

export default function App() {
  const [expression, setExpression] = useState("");
  const [fields, setFields] = useState([]);
  const [inputs, setInputs] = useState({});
  const [result, setResult] = useState("");
  const [error, setError] = useState(true);

  const [nodes, setNodes, onNodesChange] = useNodesState(undefined);
  const [edges, setEdges, onEdgesChange] = useEdgesState(undefined);

  const handleInputChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    if (event.target.type === "number") {
      value = parseInt(value)
    }

    setInputs({
      ...inputs,
      [name]: value
    });
  }

  useEffect(() => {
    if (expression === "") {
      return
    }

    getResult(expression, inputs).then((result) => {
      if (result) {
        if (result.hasOwnProperty('result')) {
          setResult(String(result.result))
          setError(false)
        } else {
          setError(true)
        }
      }
    })
    getFields(expression).then((fields) => {
      if (fields) {
        setFields(fields)
      }
    });
    getAST(expression).then((ast) => {
      if (ast && !ast.hasOwnProperty('error')) {
        const [nodes, edges] = parseAST(ast, 0, 0);
        setNodes(nodes);
        setEdges(edges);
      }
    });
  }, [expression, inputs])

  const handleExpressionChange = (event) => {
    const expression = event.target.value;

    setExpression(expression);
  }

  const getFields = (expression) => {
    return fetch("https://filters-playground.firewall.workers.dev/fields", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ expression: expression })
    })
      .then(res => res.json())
      .then((fields) => {
        if (fields.error) {
          return null
        }

        fields = [...new Set(fields)].sort((a, b) => expression.indexOf(a.name) - expression.indexOf(b.name)) // only unique + order by appearence
        return fields
      }, (error) => {
        console.log(error)
      })
  }

  const getAST = (expression) => {
    return fetch("https://filters-playground.firewall.workers.dev/ast", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ expression: expression })
    })
      .then(res => res.json())
      .then((ast) => {
        return ast;
      }, (error) => {
        console.log(error)
      })
  }

  const getResult = (expression, inputs) => {
    return fetch("https://filters-playground.firewall.workers.dev/execute", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        expression: expression,
        fields: inputs
      })
    })
      .then(res => res.json())
      .then((result) => {
        return result;
      }, (error) => {
        console.log(error)
      })
  }

  const getDefaultValue = (typeName, index) => {
    if (typeName === "Bool") {
      return true
    }
    if (typeName === "Int") {
      return index * 100
    }
    if (typeName === "Bytes") {
      return "foo"
    }
    if (typeName === "Ip") {
      return "192.0.0.1"
    }
    if (typeName === "Map") {
      return '{}' // TODO
    }
    if (typeName === "Array") {
      return "[]" // TODO
    }
  }

  const colors = ["red", "purple", "green"];

  let generation = 0;
  const contentChange = (e) => {
    let expression = e.target.innerText;
    generation++;
    let targetGeneration = generation;

    getFields(expression).then((fields) => {
      if (generation > targetGeneration) {
        return
      }

      if (fields) {
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];
          const color = colors[i % colors.length];
          expression = expression.replaceAll(field.name, `<span class="bg-${color}-200 text-${color}-700 rounded-full px-5 py-2 font-bold text-2xl">${field.name}</span>`);
        }
      }

      let range = document.createRange()
      let sel = window.getSelection()
      let oldRange = sel.getRangeAt(0);

      let targetOffset = oldRange.startOffset;
      let node = oldRange.startContainer;
      while (true) {
        if (node.parentNode != e.target) {
          node = node.parentNode
        }

        node = node.previousSibling
        if (!node) {
          break
        }

        targetOffset += node.textContent.length
      }

      e.target.innerHTML = expression;

      let currentOffset = 0;
      for (let node of e.target.childNodes) {
        if (node.firstChild) {
          node = node.firstChild
        }

        if (currentOffset + node.textContent.length >= targetOffset) {
          range.setStart(node, targetOffset - currentOffset);
          sel.removeAllRanges()
          sel.addRange(range)
          break;
        }

        currentOffset += node.textContent.length
      }

      setExpression(e.target.textContent)
    });
  }

  const renderResult = () => {
    if (expression.length == 0) {
      return <></>
    }

    if (error) {
      return <span class="text-red-400">⇒ error</span>
    }

    return <span><span class="text-slate-500">⇒ {result}</span></span>
  }

  return (
    <>
      <div class="text-center">
        <div class="pt-10 text-2xl"><i class="fa-solid fa-play text-4xl align-middle text-slate-500"></i><span class="ml-4 align-middle">filters playground</span></div>
        <div class="mt-10 w-full py-10 px-20">
          <div class="bg-white border-slate-200 border-4 text-slate-900 rounded-md p-10 text-3xl font-normal">
            <span contentEditable onInput={contentChange}></span>
            <span class="ml-5">{renderResult()}</span>
          </div>
        </div>
        <div class="rounded-md mx-10">
          <table class="mx-auto ">
            {fields.map((field, i) => {
              if (inputs[field.name] === undefined) {
                inputs[field.name] = getDefaultValue(field.type, i)
              }
              console.log(inputs[field.name])

              const color = colors[i % colors.length]
              return <tr>
                <td class="align-middle text-right"><div class={`inline bg-${color}-200 text-${color}-700 rounded-full px-5 py-2 font-bold text-xl`}>{field.name}</div></td>
                <td class="align-middle py-2"><input class="bg-white border-slate-200 border-4 text-slate-900 ml-5 py-3 px-5 rounded-md font-normal text-2xl w-80" value={inputs[field.name]} onChange={(e) => {
                  let value = e.target.value;
                  if (field.type === "Int") {
                    value = parseInt(value)
                    if (isNaN(value)) {
                      return
                    }
                  }

                  const inputs2 = { ...inputs, [field.name]: value };
                  setInputs(inputs2)
                }} /></td>
              </tr>;
            })}
          </table>
      </div>
      <div className="d-flex flex-row mb-3 justify-content-center">
        <div className="p-2">
        {nodes &&
                <Flow initialNodes={nodes} initialEdges={edges} />
              }
        </div>
      </div>
      </div>
    </>
  );
}
